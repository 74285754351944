<template>
  <div ref="navigatorSearch" class="lad-navigation--search">
    <transition name="fade-transform" mode="out-in">
      <div class="wrapper-search-autocomplete">
        <LadAutocompleteSearch
          v-if="showSearch && show"
          :key="`navigator-search`"
          :name="`navigator-search`"
          @on-blur="handleBlur"
          @on-focus="handleFocus"
          @on-change="handleChange"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LadNavigationSearch',
  components: {
    LadAutocompleteSearch: () =>
      import(
        /* webpackChunkName: "lad-autocomplete-search" */ '@/components/search/index'
      ),
  },
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      query: '',
    }
  },
  computed: {
    showSearch() {
      return this.$route.name !== 'index'
    },
  },
  methods: {
    handleBlur() {
      const item = document.querySelector('.lad-navigation--search')
      const shoppingCart = document.querySelector('.lad-navigation--menu')

      if (!!item && !this.query) {
        item.classList.remove('on-focus')
        shoppingCart.classList.remove('hiden-shopping-cart')
      }
    },
    handleFocus() {
      const item = document.querySelector('.lad-navigation--search')
      const shoppingCart = document.querySelector('.lad-navigation--menu')

      if (item) {
        item.classList.add('on-focus')
        shoppingCart.classList.add('hiden-shopping-cart')
      }
    },
    handleChange(query) {
      this.query = query
    },
  },
}
</script>

<style lang="scss">
.lad-navigation {
  &--search {
    padding: 0;
    max-width: 100%;
    justify-self: end;

    .el-loading-mask {
      height: 40px !important;
    }

    .wrapper-search-autocomplete {
      position: relative;
    }

    .lad-autocomplete-search-wrapper #autosuggest {
      .after-input {
        display: none;
      }

      .before-input {
        i {
          height: 45px;
          line-height: 45px;
        }
      }

      &__input {
        padding: 0 10px 0 0;
        width: 0;
        height: 45px;
        border-right: 1px solid #dcdfe6;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        font-size: 14px;

        &::placeholder {
          color: #757575;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  &--search:not(.on-focus) {
    .autosuggest__results-container {
      display: none !important;
    }
  }

  &--menu.hiden-shopping-cart {
    display: none;

    @media screen and (min-width: 768px) {
      display: grid;
    }
  }

  &--search.on-focus {
    position: absolute;
    top: 20px;
    width: 100%;
    padding: 0 20px;

    .lad-autocomplete-search-wrapper #autosuggest {
      background-color: white;

      &__input {
        padding: 0 10px 0 10px;
        width: 100%;
        transition: width 0.2s ease;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &--search {
      position: relative;
      max-width: 500px;
      padding: 20px;
      justify-self: unset;

      .lad-autocomplete-search-wrapper #autosuggest {
        &__input {
          padding: 0 10px 0 0;
          width: 100%;
        }
      }
    }

    &--search.on-focus {
      position: relative;
      top: unset;
      width: unset;
      padding: 20px 0 20px 20px;

      .lad-autocomplete-search-wrapper #autosuggest {
        background-color: unset;

        &__input {
          padding: 0 10px 0 0;
          width: 100%;
          transition: unset;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    &--search {
      padding-right: 0;
    }
  }
}
</style>
